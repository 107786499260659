window.console = window.console || { log: function () {} };

(function ($) {
  var app = window.app || {};
  app.conf = window.app.conf || {};

  // Passive event listeners
  // https://stackoverflow.com/questions/60357083/does-not-use-passive-listeners-to-improve-scrolling-performance-lighthouse-repo
  jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
      this.addEventListener('touchstart', handle, { passive: jQuery.inArray('noPreventDefault', ns) === -1 });
    },
  };
  jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
      this.addEventListener('touchmove', handle, { passive: jQuery.inArray('noPreventDefault', ns) === -1 });
    },
  };

  app.utils = {
    debounce: function (func, wait, immediate) {
      var timeout, args, context, timestamp, result;

      var later = function () {
        var last = Date.now() - timestamp;
        if (last < wait) {
          timeout = setTimeout(later, wait - last);
        } else {
          timeout = null;
          if (!immediate) {
            result = func.apply(context, args);
            context = args = null;
          }
        }
      };

      return function () {
        context = this;
        args = arguments;
        timestamp = Date.now();
        var callNow = immediate && !timeout;
        if (!timeout) {
          timeout = setTimeout(later, wait);
        }
        if (callNow) {
          result = func.apply(context, args);
          context = args = null;
        }

        return result;
      };
    },
    isSessionStorageSupported: function () {
      try {
        var storage = window.sessionStorage;
        storage.setItem('__test__', 'test');
        storage.removeItem('__test__');
        return true;
      } catch (e) {
        return false;
      }
    },
    isTouchCapable: function () {
      return 'ontouchstart' in window || (window.DocumentTouch && document instanceof window.DocumentTouch) || navigator.maxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0;
    },
    getQueryStringParameter: function (name) {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]'); // eslint-disable-line no-useless-escape
      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      var results = regex.exec(location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },
    removeQueryStringParameter: function (url, parameter) {
      //prefer to use l.search if you have a location/link object
      var urlparts = url.split('?');
      if (urlparts.length >= 2) {
        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0; ) {
          //idiom for string.startsWith
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {
            pars.splice(i, 1);
          }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
      }
      return url;
    },
    updateQueryStringParameter: function (url, key, value) {
      if (!url) url = window.location.href;
      var re = new RegExp('([?&])' + key + '=.*?(&|#|$)(.*)', 'gi'),
        hash;

      if (re.test(url)) {
        if (typeof value !== 'undefined' && value !== null) {
          return url.replace(re, '$1' + key + '=' + value + '$2$3');
        } else {
          hash = url.split('#');
          url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
          if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
            url += '#' + hash[1];
          }
          return url;
        }
      } else {
        if (typeof value !== 'undefined' && value !== null) {
          var separator = url.indexOf('?') !== -1 ? '&' : '?';
          hash = url.split('#');
          url = hash[0] + separator + key + '=' + value;
          if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
            url += '#' + hash[1];
          }
          return url;
        } else {
          return url;
        }
      }
    },
  };

  $.fn.listGroupSelector = function () {
    var $listGroups = $(this);
    $listGroups.each(function () {
      var $radios = $(this).find(':radio');
      $radios
        .on('change', function () {
          $radios.each(function () {
            var $listGroupItem = $(this).closest('.list-group-item');

            if ($(this).is(':checked')) {
              $listGroupItem.addClass('list-group-item-selected');
            } else {
              $listGroupItem.removeClass('list-group-item-selected');
            }
          });
        })
        .filter(':checked')
        .change();
    });
    return $listGroups;
  };

  app.initHideShowPassword = function (options) {
    $('input[type="password"]').hideShowPassword(
      {
        show: false,
        toggle: {
          className: 'btn btn-plain btn-sm btn-showhidepassword',
        },
        states: {
          shown: {
            toggle: {
              content: options.shownText,
              attr: {
                'aria-label': options.shownText,
                title: options.shownText,
              },
            },
          },
          hidden: {
            toggle: {
              content: options.hiddenText,
              attr: {
                'aria-label': options.hiddenText,
                title: options.hiddenText,
              },
            },
          },
        },
      },
      true,
    );
  };

  app.initMainLightbox = function () {
    $('.lightbox:not(.hidden):not([data-size])').magnificPopup({
      type: 'image',
      verticalFit: false,
      image: {
        titleSrc: function (item) {
          return item.el.attr('mfp-title') || item.el.attr('title') || '';
        },
      },
      gallery: {
        enabled: true,
        tCounter: app.magnificPopupCounterText,
      },
      callbacks: {
        beforeOpen: function () {
          $('body').addClass('mfp-active');
        },
        beforeClose: function () {
          $('body').removeClass('mfp-active');
        },
        open: function () {
          window.location = '#popup';
        },
        close: function () {
          if (window.location.hash == '#popup') {
            window.history.back();
          }
        },
      },
    });
  };

  $(function () {
    $('.alert').alert();

    $('[data-toggle="tooltip"]').tooltip();

    app.initMainLightbox();

    $('[data-dialog="ajax"]').magnificPopup({
      type: 'ajax',
      fixedBgPos: true,
      closeBtnInside: true,
      callbacks: {
        beforeOpen: function () {
          $('body').addClass('mfp-active');
        },
        beforeClose: function () {
          $('body').removeClass('mfp-active');
        },
        open: function () {
          window.location = '#popup';
        },
        close: function () {
          if (window.location.hash == '#popup') {
            window.history.back();
          }
        },
        parseAjax: function (response) {
          var $wrapper = $('<div class="white-popup"></div>');
          $wrapper.html(response.data);
          response.data = $wrapper;
        },
      },
    });

    $('[data-dialog="inline"]').magnificPopup({
      type: 'inline',
      fixedBgPos: true,
      closeBtnInside: true,
      callbacks: {
        beforeOpen: function () {
          $('body').addClass('mfp-active');
        },
        beforeClose: function () {
          $('body').removeClass('mfp-active');
        },
        open: function () {
          window.location = '#popup';
        },
        close: function () {
          if (window.location.hash == '#popup') {
            window.history.back();
          }
        },
      },
    });

    $('[data-dialog="video"]').magnificPopup({
      type: 'iframe',
      iframe: {
        patterns: {
          youtube: {
            index: 'youtube.com',
            id: 'v=',
            src: '//www.youtube-nocookie.com/embed/%id%?autoplay=1&rel=0',
          },
        },
      },
      gallery: {
        enabled: true,
      },
      callbacks: {
        beforeOpen: function () {
          $('body').addClass('mfp-active');
        },
        beforeClose: function () {
          $('body').removeClass('mfp-active');
        },
        open: function () {
          window.location = '#popup';
        },
        close: function () {
          if (window.location.hash == '#popup') {
            window.history.back();
          }
        },
      },
    });

    $(window).on('hashchange', function () {
      if (location.hash != '#popup') {
        $.magnificPopup.close();
      }
    });

    app.formSubmitFeedback = function (form) {
      var loadingText;
      var language = window.app.conf.language || 'fr';

      if (language === 'fr') {
        loadingText = 'Chargement...';
      } else {
        loadingText = 'Loading...';
      }
      $(form).find('.btn[type="submit"]').data({ loadingText: loadingText }).button('loading');
    };

    $(document).on('submit', 'form', function () {
      app.formSubmitFeedback(this);
    });

    $(window).on('pageshow', function (event) {
      // https://stackoverflow.com/questions/11871253/execute-document-ready-even-if-user-came-to-the-page-by-hitting-the-back-button/48213805
      // https://stackoverflow.com/questions/2638292/after-travelling-back-in-firefox-history-javascript-wont-run/12648785#12648785
      if (event.originalEvent.persisted) {
        $('form .btn[type="submit"]').button('reset');
      }
    });

    $('[data-toggle="popover"]').popover();

    $('.dropdown-submenu > a').on('click', function (e) {
      var $current = $(this).next('.dropdown-menu');
      var closed = $current.hasClass('hidden');

      // prevent parent dropdown from closing
      e.stopImmediatePropagation();
      e.preventDefault();

      $(this).parents('.dropdown-menu').find('.dropdown-menu').addClass('hidden');

      if (closed) {
        $current.removeClass('hidden');
      }
    });

    $('.navbar .dropdown').on('shown.bs.dropdown', function () {
      var $el = $(this);
      // wait for dropdowns to close
      setTimeout(function () {
        var top = $el.offset().top;
        // mobile navbar only
        if (top > 150) {
          $('html,body').animate({ scrollTop: top }, 150);
        }
      }, 10);
    });

    $('.language-banner').on('closed.bs.alert', function () {
      $.post(app.cookieSessionURL, { 'language-banner-closed': true });
    });

    $('.country-banner').on('closed.bs.alert', function () {
      $.post(app.cookieSessionURL, { 'country-banner-closed': true });
    });

    $('[data-dismissable-alert-id]').on('closed.bs.alert', function () {
      $.post(app.cookieSessionURL, { 'banner-dismissed': $(this).data('dismissable-alert-id') });
    });
  });
})(jQuery);
